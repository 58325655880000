import { ChakraProvider, Box, Text, Button, useColorModeValue, SimpleGrid, Flex } from '@chakra-ui/react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import LoadingSpinner from 'components/LoadingSpinner';
import PrivateRoute from 'components/PrivateRoute';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import './utils/assets/css/App.css';
import keycloak from './utils/keycloak';
import AdminLayout from './utils/layouts';
import AuthLayout from './utils/layouts/auth/index';
import theme from './utils/theme/theme';

const App = () => {
  const [keycloakError, setKeycloakError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  useEffect(() => {
    // Check if Keycloak is initialized and ready, or if there's an error
    keycloak.onReady = () => setIsLoading(false);
    keycloak.onAuthError = () => setKeycloakError('Errore di connessione alla sessione');
    keycloak.onAuthLogout = () => setKeycloakError('L\'utente ha effettuato la disconnessione');
    keycloak.onAuthRefreshError = () => setKeycloakError('Errore durante l\'aggiornamento del token di sessione');
    keycloak.onAuthRefreshSuccess = () => setKeycloakError(null);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <ReactKeycloakProvider
          authClient={keycloak}
          onEvent={(event) => {
            if (event === 'onAuthSuccess') {
              if (keycloak.token) {
                localStorage.setItem('kc-token', keycloak.token);
              }
            } else {
              setKeycloakError('Errore durante la connessione al provider di autenticazione');
              setIsLoading(false);
            }
          }}
          onTokens={(tokens) => {
            if (tokens.token) {
              localStorage.setItem('kc-token', tokens.token);
            }
          }}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : keycloakError ? (
            <Flex
              height="100vh"
              alignItems="center"
              justifyContent="center"
              backgroundColor="#f0f4f8"
            >
              <SimpleGrid
                backgroundColor="white"
                borderRadius="20px"
                boxShadow={shadow}
                padding="20px"
              >
                <Box textAlign="center">
                  <Text
                    fontSize="xl"
                    color="primary"
                  >
                    {keycloakError}
                  </Text>
                  <Button
                    mt={5}
                    padding="0px 8px"
                    borderRadius="5px"
                    marginLeft="0px"
                    fontSize="xs"
                    bg="white"
                    height="20px"
                    color="#688CC8"
                    border="1px solid #688CC8"
                    onClick={() => window.location.reload()}
                  >
                    Riprova
                  </Button>
                </Box>
              </SimpleGrid>
            </Flex>
          ) : (
            <HashRouter>
              <Switch>
                <Route path="/auth" component={AuthLayout} />
                <PrivateRoute path="/admin" component={AdminLayout} />
                <Redirect from="/" to="/admin" />
              </Switch>
            </HashRouter>
          )}
        </ReactKeycloakProvider>
      </React.StrictMode>
    </ChakraProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
